/* @import url("https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&display=swap"); */
/* Start expanded google fonts from above */
/* cyrillic-ext */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans Italic"), local("PTSans-Italic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizYRExUiTo99u79D0e0ysmIEDQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans Italic"), local("PTSans-Italic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizYRExUiTo99u79D0e0w8mIEDQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans Italic"), local("PTSans-Italic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizYRExUiTo99u79D0e0ycmIEDQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans Italic"), local("PTSans-Italic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizYRExUiTo99u79D0e0x8mI.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold Italic"), local("PTSans-BoldItalic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizdRExUiTo99u79D0e8fOydIhUdwzM.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold Italic"), local("PTSans-BoldItalic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizdRExUiTo99u79D0e8fOydKxUdwzM.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold Italic"), local("PTSans-BoldItalic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizdRExUiTo99u79D0e8fOydIRUdwzM.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold Italic"), local("PTSans-BoldItalic"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizdRExUiTo99u79D0e8fOydLxUd.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans"), local("PTSans-Regular"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79D0-ExdGM.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans"), local("PTSans-Regular"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79D0aExdGM.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans"), local("PTSans-Regular"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79D0yExdGM.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("PT Sans"), local("PTSans-Regular"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79D0KExQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold"), local("PTSans-Bold"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh0OOtLQ0Z.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold"), local("PTSans-Bold"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh0OqtLQ0Z.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold"), local("PTSans-Bold"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh0OCtLQ0Z.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("PT Sans Bold"), local("PTSans-Bold"),
    url(https://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh0O6tLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* End expanded google fonts from above */

/* Start custom fonts */
@font-face {
  font-family: ClearSans-Bd;
  src: url("../../common/fonts/clearsans/ClearSans-Bold.ttf");
}

@font-face {
  font-family: ClearSans-BdIt;
  src: url("../../common/fonts/clearsans/ClearSans-BoldItalic.ttf");
}

@font-face {
  font-family: ClearSans-It;
  src: url("../../common/fonts/clearsans/ClearSans-Italic.ttf");
}

@font-face {
  font-family: ClearSans-Lt;
  src: url("../../common/fonts/clearsans/ClearSans-Light.ttf");
}

@font-face {
  font-family: ClearSans-Md;
  src: url("../../common/fonts/clearsans/ClearSans-Medium.ttf");
}

@font-face {
  font-family: ClearSans-MdIt;
  src: url("../../common/fonts/clearsans/ClearSans-MediumItalic.ttf");
}

@font-face {
  font-family: ClearSans;
  src: url("../../common/fonts/clearsans/ClearSans-Regular.ttf");
}

@font-face {
  font-family: ClearSans-Th;
  src: url("../../common/fonts/clearsans/ClearSans-Thin.ttf");
}
/* End custom fonts */

