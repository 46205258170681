.home {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .ribbon {
        font-family: 'ClearSans-Bd', sans-serif;
        color: rgba(0,0,0,0.72);
        text-align: left;
        margin: 0 10%;

        h1 {
            padding: 20px 0 20px 0;
        }

        @media only screen and (min-width: 1200px) {
            margin: 0 20%;
        }
    }

    .sans-header {
        padding: 25px 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin: 0 10%;
        border-bottom: 4px solid #F1F5F7;
        @media only screen and (min-width: 480px) {
            flex-direction: row;
        }
        @media only screen and (min-width: 1200px) {
            margin: 0 20%;
        }
    }

    .logo {
        height: 60px;
        width: 120px;
        min-width: 120px;
        background: url('../../common/img/logo-sans.svg') 0 0 no-repeat;
        background-size: contain;
        margin: auto;
        @media only screen and (min-width: 480px) {
            margin: 0;
        }
    }

    .welcome {
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        margin-bottom: 20px;
        @media only screen and (min-width: 480px) {
            margin: 0;
            margin-right: 1px; // prevents icon from getting cut off
        }

        a {
            color: #005980;
            background: url('../../common/img/login-icon.svg') 0 0 no-repeat;
            height: 32px;
            width: 32px;
            background-size: contain;
        }
    }

    .links {
        padding-top: 33px;

        a {
            font-family: 'Diavlo Light', sans-serif;
            color: #005980;
            padding: 5px 10px;
            font-size: 17px;
            text-decoration: none;

            &:last-of-type {
                padding-right: 0;
            }
        }

        li {
            display: inline;
            border-left: 1px solid #ddd;

            &:first-of-type {
                border-left: none;
            }
        }

        ul:hover li {

            a {
                color: #ddd;
            }

            &:hover a {
                color: #005980;
            }
        }

    }

    .lab-display {
        margin: 20px 10%;

        @media only screen and (min-width: 1200px) {
            margin: 20px 20%;
        }
    }

    .content {
        flex: 1;
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: rgb(241, 245, 247);
        font-size: 14px;
        color: rgba(0, 0, 0, 0.64);
        height: 31px;

        p {
            margin-bottom: 0;
            margin-right: 1rem;
        }

        a {
            color: inherit;
        }
    }
}
