.expandable {
    margin-bottom: 1.25rem;

    .expandable-target {
        cursor: pointer;
        font-family: 'ClearSans-Bd', sans-serif;
    }
    .card-body {
        font-family: 'PT Sans', sans-serif;
    }
    .fa {
        padding-right: 10px;
    }
    .title.card-header {
        display: flex;
        justify-content: space-between;
    }

    &__darkBlue {
        >.title {
            background: rgb(0, 89, 128); // dark blue
            color: rgb(255, 255, 255);
        }
    }

    &__darkGrey {
        >.title {
            color: rgb(255, 255, 255);
            background: rgb(120, 122, 123)
        }

    }
}
