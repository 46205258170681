.attachment-renderer{
    font-size: 0.9rem;
    width: 100%;;
    tr:nth-child(odd){
        background-color: #f3f3f3;
    }

    &-field{
        padding-right: 1rem;
    }

    &-btn{
        cursor: pointer;
    }

    &-btn:hover{
        color: #007bff;
    }

    &-heading{
        margin-bottom: 0;
        font-weight: bold;
    }

}