.status-label {
  margin-bottom: 1em;
  color: #999;
}

.status-label .caption {
  font-size: small;
}

.status-label .caption button {
  margin-left: 1em;
  border: 0;
  background-color: transparent;
  color: #999;
  text-decoration: underline;
}

.status-label .caption button:hover {
  color: #fff;
  background-color: #f00;
  text-decoration: none;
  border-radius: 5px;
}

.status-label .label {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin-right: 1em;
  color: #666;
}

.status-label .label.success {
  color: green;
}

.status-label .label.error {
  color: red;
}
