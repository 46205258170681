@import '../node_modules/font-awesome/css/font-awesome.min.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

// credit to mark horlbeck for the -btn css
.sans-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: #005980;
  color: #FFF;
  font-family: 'ClearSans-Bd', sans-serif;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  border: 1px solid #0078ac;
  
  &:hover{
    background-color: #0078ac;
    border: 1px solid #0095d5;
  }

  &:active {
    background-color: #fff;
    color: #0078ac;
  }

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #666;
  }

  &__download-spinner {
    $spinner-size: 1.5rem;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #747474;
    border-radius: 50%;
    width: $spinner-size;
    height: $spinner-size;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Utility CSS
.flex{
  display: flex;
}

.column {
  flex-direction: column;
}
@mixin w($name) {
  .w-#{$name} {
    width: $name * 1rem;
  }
}
@include w(4);
@include w(8);
@include w(12);
@include w(16);
