.idapro-platform {
  display: block;
  width: auto;
}

.idapro-licensing-submit-btn {
  display: block;
  margin-bottom: 32px;
  background-color: #005980;
  color: #FFF;
  font-family: 'ClearSans-Bd', sans-serif;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  border: 1px solid #0078ac;
}

.idapro-licensing-submit-btn:hover {
  background-color: #0078ac;
  border: 1px solid #0095d5;
}

.idapro-licensing-submit-btn:active {
  background-color: #fff;
  color: #0078ac;
}

.idapro-licensing-submit-btn:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #666;
}

.idapro-licensing-submit-btn__download-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #747474;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

.idapro-success-message {
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
