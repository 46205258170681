.region-selector {
  margin-bottom: 1em;
}

.region-selector label {
  margin-bottom: 1px;
  display: block;
}

.region-selector select {
  min-width: 200px;
}
