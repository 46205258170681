.license-form{
    margin-bottom: 1rem;
    margin-top: 1rem;
    .license-field-row{
        display: flex;
        margin-bottom: 1rem;
    }

    .license-error{
        font-size: .75rem;
        color: #ff0000;
    }

    .license-form-field > label{
        margin-right: 1rem;
    }
}

.ami{
    margin-bottom: 1rem;

    &>p{
        margin-block-end: 0;
    }
}

.ami-form-field-container{
    display: flex;
    flex-direction: column;
    input{
        width: 16rem;
        margin-bottom: 1rem;
    }
}

.licensing-display__license{
    font-weight: bold;
    cursor: pointer;
    
}


.licensing-error-display {
    color: red;
    font-size: .9rem;
}

.licensing-error-display__message{
    margin: unset;
}

.licensing-error-display__warning{
    margin: unset;
}

.licensing-usage-confirmation__checkbox{
    margin-right: 0.5rem;
}